var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.networkType
    ? _c("div", { staticClass: "bg-near-black sidenav" }, [
        _c(
          "div",
          { staticClass: "tr flex flex-wrap" },
          [
            _vm.isStudio
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    disable: _vm.disableAccordingToUser,
                    onClick: function () {
                      return _vm.$router.push("/overview")
                    },
                    text: "OVERVIEW",
                    icon: "dashboard",
                    active: _vm.isPath("/overview"),
                  },
                })
              : _vm._e(),
            _vm.$permissions.isViewEpisodeShowAllowed("") &&
            !_vm.disableAccordingToUser &&
            _vm.isStudio
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    icon: "library_music",
                    onClick: function () {
                      return _vm.$router.push("/audio")
                    },
                    text: "AUDIO",
                    active: _vm.isPathRegex(/^(\/audio|\/audio-editor)/),
                  },
                })
              : _vm._e(),
            _vm.isYoutube
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    disable: _vm.disableAccordingToUser,
                    icon: "home",
                    onClick: function () {
                      return _vm.selectedProduct === "BLOG"
                        ? _vm.$router.push("/home/blog")
                        : _vm.$router.push("/home")
                    },
                    text: "Home",
                    active: _vm.isPathRegex(/^(\/home)/),
                  },
                })
              : _vm._e(),
            _vm.$permissions.isViewTagShowAllowed("") &&
            _vm.selectedProduct === "VIDEO" &&
            (_vm.isStudio || _vm.isYoutube)
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    icon: "local_offer",
                    onClick: function () {
                      return _vm.$router.push("/tags")
                    },
                    text: "TAGS",
                    active: _vm.isPath("/tags"),
                  },
                })
              : _vm._e(),
            _vm.isYoutubeOld ||
            _vm.isYoutubeNew ||
            _vm.selectedProduct === "VIDEO"
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    icon: "psychology",
                    onClick: function () {
                      return _vm.$router.push("/ai")
                    },
                    text: "AI",
                    active: _vm.isPathRegex(/^(\/ai)/),
                  },
                })
              : _vm._e(),
            _vm.$permissions.isPublishPodcastShowAllowed("") && _vm.isStudio
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    disable: _vm.disableAccordingToUser,
                    icon: "publish",
                    onClick: function () {
                      return _vm.$router.push("/publish")
                    },
                    text: "PUBLISH",
                    active: _vm.isPathRegex(/^(\/publish)/),
                  },
                })
              : _vm._e(),
            (_vm.$permissions.isCampaignViewAllowed() || _vm.isShowAdmin) &&
            _vm.isStudio &&
            !_vm.isPathRegex(/^(\/permission-error)/)
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    disable: _vm.disableAccordingToUser,
                    icon: "monetization_on",
                    onClick: function () {
                      return _vm.$router.push("/monetize")
                    },
                    text: "MONETIZE",
                    active: _vm.isPathRegex(/^(\/monetize)/),
                  },
                })
              : _vm._e(),
            (_vm.$permissions.isCampaignViewAllowed() || _vm.isShowAdmin) &&
            _vm.isStudio &&
            !_vm.isPathRegex(/^(\/permission-error)/)
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    disable: _vm.disableAccordingToUser,
                    type: "img",
                    onClick: function () {
                      return _vm.$router.push("/campaign")
                    },
                    text: "CAMPAIGN",
                    svgActive: _vm.campaign_icon_active,
                    svgInactive: _vm.campaign_icon,
                    active: _vm.isPathRegex(/^(\/campaign)/),
                  },
                })
              : _vm._e(),
            _vm.$permissions.isViewInsightsShowAllowed("") && _vm.isStudio
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    disable: _vm.disableAccordingToUser,
                    icon: "bar_chart",
                    onClick: _vm.handleInsights,
                    text: "INSIGHTS",
                    active: _vm.isPathRegex(/^(\/insights)/),
                  },
                })
              : _vm._e(),
            _vm.isVideo &&
            _vm.isNetworkOwner &&
            ["BLOG", "VIDEO"].includes(_vm.selectedProduct)
              ? _c("BaseMenuItem", {
                  staticClass: "w-100",
                  attrs: {
                    disable: _vm.disableAccordingToUser,
                    icon: "verified",
                    onClick: function () {
                      return _vm.$router.push("/rewards")
                    },
                    text: "Rewards",
                    active: _vm.isPathRegex(/^(\/rewards)/),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }